<template>
  <div>
    <el-dialog
      title="查看补贴等级"
      :visible.sync="viewVisible"
      width="500px"
      :before-close="handleClose">
      <div style="padding-right: 20px">
        <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="等级名称：" prop="name">
            <div v-if="ruleForm.nextFlag == 0">
              <span>{{ruleForm.levelName}}</span>
            </div>
            <div v-else>
              <span>{{ruleForm.nextLevelName}}</span>
              <span style="color: #999999;padding-left: 10px">( 当前为{{ruleForm.levelName}} )</span>
            </div>
          </el-form-item>
          <el-form-item label="年龄：" prop="type">
           <span>{{ruleForm.age}}</span>
          </el-form-item>
          <el-form-item label="身份类别：" prop="resource">
            <span>{{['普通老人', '低保老人', '失能老年人', '低保失能老年人'][ruleForm.type*1]}}</span>
          </el-form-item>
          <el-form-item label="月标准：" prop="num">
            <div v-if="ruleForm.nextFlag == 0">
              <span>{{ruleForm.subsidyStandard}}</span>
            </div>
            <div v-else>
              <span>{{ruleForm.nextSubsidyStandard}}</span>
              <span style="color: #999999;padding-left: 10px">(当前为{{ruleForm.subsidyStandard}} 元)</span>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="handleClose">关闭</el-button>
  </span>
    </el-dialog>

  </div>
</template>

<script>
  export default {
    name: "viewLevel",
    props: {
      viewVisible: {
        type: Boolean,
        default: false
      },
      rowItem: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    data() {
      return {
        ruleForm: {}
      }
    },
    mounted() {
      this.ruleForm = this.rowItem
    },
    methods: {
      handleClose() {
        this.$emit('update:viewVisible', false)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .w200 {
    width: 300px;
  }
</style>
